<template>
  <lf-toast />
  <test-mode-alert v-if="inTestMode" />
  <div id="app-dashboard" class="flex h-screen w-screen">
    <dashboard-nav v-if="!isPublicRoute && user?.id" :test-mode="inTestMode" />
    <div
      id="appWrapper"
      class="overflow-auto flex-1 flex-col"
      :class="{ 'pt-12': inTestMode }"
    >
      <loader :is-loading="isGlobalLoading" />
      <router-view />
    </div>
    <transition name="slide-fade-notes">
      <notes
        v-show="dealNotesOptions.state !== NoteState.Closed"
        class="w-1/4 bg-white shadow-2xl z-100"
      />
    </transition>
  </div>
</template>
<script setup lang="ts">
import { useRoute } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";
import { useRefreshToken } from "@/hooks/auth";
import * as Yup from "yup";
import { PUBLIC_PAGES } from "./helpers/constants";
import { useStore } from "vuex";
import { useLocalStorageSetting } from "@/hooks/options";
import type { IUser } from "@/models/users";
import type { IClient } from "@/models/clients";

import LfToast from "@/components/ui/LfToast.vue";
import DashboardNav from "@/components/DashboardNav.vue";
import TestModeAlert from "@/components/ui/TestModeAlert.vue";
import Notes from "@/components/deals/Notes.vue";
import { useI18n } from "vue-i18n";
import { logWarning } from "./helpers/formatting";
import { useFullStory } from "@/hooks/fullStory";
import { DEFAULT_DEAL_NOTES_OPTIONS } from "@/helpers/constants/deals";
import { NoteState } from "@/enums/notes";

const { initFullStory } = useFullStory();
initFullStory();

const user = computed<IUser>(() => getters["auth/user"]);

Yup.setLocale({
  mixed: {
    required: "This field is required",
    notType: "Invalid type"
  },
  number: {
    positive: "Positive number"
  },
  string: {
    url: "Invalid URL"
  }
});

const route = useRoute();
const { t } = useI18n();
const { getters } = useStore();
const dealNotesOptions = useLocalStorageSetting(
  "dealNotesOptions",
  DEFAULT_DEAL_NOTES_OPTIONS
);

const isPublicRoute = computed(
  () => PUBLIC_PAGES.indexOf(route.name as string) > -1
);

const isGlobalLoading = computed<boolean>(() => getters["globalLoader"]);
const authClientSettings = computed<IClient>(
  () => getters["auth/authClientSettings"]
);
const inTestMode = ref(false);

watch(
  () => authClientSettings.value,
  () => {
    inTestMode.value = !!(
      authClientSettings.value?.in_test_mode &&
      authClientSettings.value.id === user.value?.client_id
    );
  }
);

onMounted(async () => {
  logWarning(
    `${t("COMMON.CONSOLE_WARNING_DESC_1")}.`,
    `${t("COMMON.CONSOLE_WARNING_DESC_2")}.`
  );
  useRefreshToken(10);
});
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #f1f4f7;
}
.slide-fade-notes-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-notes-leave-active {
  transition: all 0.2s ease-out;
}

.slide-fade-notes-enter-from,
.slide-fade-notes-leave-to {
  @apply transform translate-x-full;
  opacity: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.autofil-transparent:-webkit-autofill,
.autofil-transparent:-webkit-autofill:hover,
.autofil-transparent:-webkit-autofill:focus,
.autofil-transparent:-webkit-autofill:active {
  box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: white;
  transition: all 9999s 9999s linear;
}

[type="checkbox"] {
  @apply h-4 w-4 border-gray-300 rounded-sm place-self-center !important;
}

[type="checkbox"]:disabled {
  @apply text-gray-300;
}

.action-menu-dd {
  @apply z-20 absolute bg-white shadow-md rounded-md py-2 text-sm text-gray-500 focus:outline-none sm:text-sm w-max;
}

.p-datatable {
  @apply border rounded overflow-hidden;
}

.p-datatable
  thead
  *:not(.p-checkbox):not(.p-checkbox-box):not(.p-checkbox-icon) {
  @apply bg-white !important;
}

.p-datatable th {
  @apply text-gray-400 font-semibold !important;
}

.p-datatable-header {
  @apply bg-white !important;
}

.icon-chevron-up {
  background: url("@/assets/icons/Chevron.svg");
  height: 16px;
  width: 16px;
  align-self: center;
}

.icon-chevron-down {
  background: url("@/assets/icons/Chevron.svg") no-repeat;
  transform: rotate(180deg);
  height: 16px;
  width: 16px;
  align-self: center;
}

.dropdown-tooltip {
  pointer-events: none;
}

.dropdown-tooltip .p-tooltip-text {
  @apply bg-black text-xs;
}

.tippy-box[data-theme~="lendflow"] {
  @apply text-xs break-words bg-grey-navigation font-normal leading-5;
}

.tippy-box[data-theme~="none"] {
  @apply bg-transparent text-gray-400 rounded-none text-sm leading-normal;

  & .tippy-content {
    @apply p-0;
  }
}

.tippy-box[data-theme~="tour"] {
  @apply text-xs break-words bg-[#7800D3] font-normal leading-5;

  & .tippy-arrow {
    @apply text-[#7800D3];
  }

  & button {
    @apply bg-[#5a188d] text-white;
  }
}
</style>
