import type { Plugin } from "vue";
import { createApp } from "vue";
import i18n from "@/i18n";
import type { ILfVuexStore } from "./models/state";
import { createPinia } from "pinia";

import App from "@/App.vue";
import globalComponents from "./globalComponents";
import ClickOutsidePlugin from "@/plugins/ClickOutsidePlugin";
import VueGtag from "vue-gtag";
import * as amplitude from "@amplitude/analytics-browser";
import Chartkick from "vue-chartkick";
import { vMaska } from "maska";
import mitt from "mitt";
import router from "@/router";
import store from "@/store";
import "@/assets/global.css";
import vTooltipable from "@/directives/tooltipable";

// eslint-disable-next-line import/extensions
import "@fontsource/inter/latin.css";
import "chartkick/chart.js";
import "@/assets/tailwind.css";
import { establishWebSocketConnection } from "./helpers/websockets";
import config from "./config";
import { plugin as VueTippy } from "vue-tippy";
import "tippy.js/dist/tippy.css";
import "@/assets/quill.css";

import * as Sentry from "@sentry/vue";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

const app = createApp(App)
  .use(router)
  .use(i18n)
  .use(store as ILfVuexStore & Plugin)
  .use(createPinia())
  // eslint-disable-next-line
  // @ts-ignore
  .use(Chartkick)
  .use(ClickOutsidePlugin)
  .use(VueTippy, {
    component: "tooltip",
    directive: "tooltip",
    defaultProps: { theme: "lendflow" }
  });

Object.entries(globalComponents).forEach(([name, component]) =>
  app.component(name, component)
);

app.config.globalProperties.emitter = mitt();

if (config.env === "production") {
  if (config.amplitudeAnalyticsKey) {
    amplitude.add(sessionReplayPlugin());
    amplitude.init(config.amplitudeAnalyticsKey, {
      fetchRemoteConfig: true,
      autocapture: true
    });
  }
  if (config.google.googleTagId) {
    app.use(
      VueGtag,
      {
        config: {
          id: config.google.googleTagId
        },
        appName: "dashboard",
        deferScriptLoad: true
      },
      router
    );
  }
}

establishWebSocketConnection();

Sentry.init({
  app,
  dsn: config.sentryDashboardDsn,
  release: config.sentryRelease,
  dist: config.sentryDist,
  integrations: [Sentry.browserTracingIntegration({ router })],
  sampleRate: 1,
  tracePropagationTargets: [config.api.baseUrl || "localhost", /^\//],
  // disable trace sampling
  tracesSampleRate: 0,
  maxBreadcrumbs: 50
});

app.directive("maska", vMaska);
app.directive("tooltipable", vTooltipable);

app.mount("#app");
